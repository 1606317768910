<template>
  <div>
    <loading :active.sync="isLoading" 
      :can-cancel="false" 
      :color="'#aa945b'"
      :is-full-page="fullPage">
    </loading>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <b>Add New Alias</b>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol col="3" v-for="(data, index) in alias.filters" :key="index">
                  <label>{{data.name}}</label>
                  <multiselect
                    v-if="data.name == 'Subject'"
                    tag-placeholder="Add this as new tag"
                    placeholder="Search or add a tag"
                    label="name"
                    track-by="id"
                    :options="data.items"
                    text-field="name"
                    v-model="new_alias.subject"
                    :taggable="true"
                  ></multiselect>

                   <multiselect
                    v-if="data.name == 'Level'"
                    tag-placeholder="Add this as new tag"
                    placeholder="Search or add a tag"
                    label="name"
                    track-by="id"
                    :options="data.items"
                    text-field="name"
                    v-model="new_alias.level"
                    :taggable="true"
                  ></multiselect>
               
              </CCol>
              <CCol col="3">
                <CInput label="Alias" placeholder="Enter Alias" v-model="new_alias.alias"/>
              </CCol>
              <CCol col="3">
                <CInput label="Topic" placeholder="Enter Topic" v-model="new_alias.topic"/>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <button type="button" class="btn btn-success mr-3" @click="addAlias">Add Alias</button>
            <button type="button" class="btn btn-danger"  @click="reset">Reset</button>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
          <CCard>
            <CCardHeader>Aliases</CCardHeader>
            <CCardBody>
            <CDataTable 
              :items="alias.data"
              :fields="fields"
              :noItemsView='{ noResults: "No Alias Found", noItems: "No Alias Found" }'
              :tableFilter='{ label : "Filter", placeholder:"Type to Search..." }'
              hover
              sorter>
              <template #sno="{item, index}">
                <td>{{index + 1}}</td>
              </template>
              <template #subject_id="{item, index}">
                <td>{{item.subject.name}}</td>
              </template>
              <template #level_id="{item, index}">
                <td>{{item.level_id == null ? '-' : item.level.name}}</td>
              </template>
              <template #action="{item, index}">
                  <td>
                      <font-awesome-icon icon="edit" style="color:green;" @click="editAlias(item)" class="mr-4"/>
                      <font-awesome-icon icon="trash-alt" style="color:red;" @click="deleteAlias(item)"/>

                  </td>
              </template>
          </CDataTable>
            </CCardBody>
          </CCard>

      </CCol>
    </CRow>

    <CModal
      title="Confirmation"
      color="success"
      centered
      :show.sync="deleteConfirmationModal"
    >
      Are you sure you want to delete an alias?
      <template slot="footer">
        <CButton color="secondary" @click="cancelDelteModal">Cancel</CButton>
        <CButton color="success" @click="confirmDeleteAlias">OK</CButton>
      </template>
    </CModal>

  <CModal
      :title="edit_alias_title"
       centered
      :show.sync="editAliasModal"
    >
      <CRow>
        <CCol col="12" v-for="(data, index) in alias.filters" :key="index" class="mt-2">
            <label>{{data.name}}</label>
            <multiselect
              v-if="data.name == 'Subject'"
              tag-placeholder="Add this as new tag"
              placeholder="Search or add a tag"
              label="name"
              track-by="id"
              :options="data.items"
              text-field="name"
              v-model="selected_item.subject"
              :taggable="true"
            ></multiselect>

              <multiselect
              v-if="data.name == 'Level'"
              tag-placeholder="Add this as new tag"
              placeholder="Search or add a tag"
              label="name"
              track-by="id"
              :options="data.items"
              text-field="name"
              v-model="selected_item.level"
              :taggable="true"
            ></multiselect>
          
        </CCol>
        <CCol col="12">
          <CInput label="Alias" placeholder="Enter Alias" v-model="selected_item.alias"/>
        </CCol>
        <CCol col="12">
          <CInput label="Topic" placeholder="Enter Topic" v-model="selected_item.topic"/>
        </CCol>
        </CRow>
      <template slot="footer">
        <CButton color="secondary" @click="cancelEditModal">Cancel</CButton>
        <CButton color="success" @click="updateAlias">Save</CButton>
      </template>
    </CModal>


  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { mapState } from "vuex";
import Vue from 'vue'


export default {
    name: 'Aliases',
    data(){
        return{
            isLoading: false,
            fullPage: true,
            edit_alias_title:"Edit Alias",
            editAliasModal:false,
            deleteConfirmationModal:false,
            selected_item:{},
            fields:[
                {key: 'sno', _style:'min-width:30px;', label:'SNo'},
                {key: 'subject_id', _style:'min-width:40px;', label:'Subject'},
                {key: 'level_id', _style:'min-width:40px;', label:'Level'},
                {key: 'alias', _style:'min-width:40px;', label:'Alias'},
                {key: 'topic', _style:'min-width:40px;', label:'Topics'},
                {key: 'action', _style:'min-width:30px;', label:'', sorter: false, },
            ],
            new_alias:{
              subject:{},
              level:{},
              topic:'',
              alias:''
            },
            
        }
    },
    computed: {
      ...mapState(["alias"]),
    },
    async mounted(){
      this.isLoading = true
      await this.$store.dispatch("auth/fetchUser");
      await this.$store.dispatch("alias/fetchAll");
      this.isLoading = false

    },
    methods:{
      getSubjectName(id){
        let name = id;
        var subjects = this.alias.filters.filter(obj => {
          return obj.name === "Subject"
        })

        console.log(subjects)
        if(subjects)
        {
          let items = subjects[0].items
          
          name = this.getName(id, items)
        }

        return name
      },
      getName(id, items){
        let name = id;

        for(let i=0; i< items.length; i++){
            if(items[i].id == id){
                name = items[i].name
            }
        }

        return name
      },
      deleteAlias(item){
        this.selected_item = item
        this.deleteConfirmationModal = true

      },
      cancelDelteModal(){
        this.selected_item = {}
        this.deleteConfirmationModal = false
      },
      async confirmDeleteAlias(){
        if(this.selected_item.id) {
            this.deleteConfirmationModal = false
            this.isLoading = true;
            this.alias.data = [];
            await this.$store.dispatch("alias/deleteAlias", this.selected_item.id);
            this.isLoading = false;
        }
      },
      cancelEditModal(){
        this.selected_item = {}
        this.editAliasModal = false
      },
      async updateAlias(){
        if(this.selected_item){
          this.editAliasModal = false
          this.loading = true
          console.log(this.selected_item);
		  let patchVars = {
            id: this.selected_item.id,
            subject_id: this.selected_item.subject.id,
            level_id: this.selected_item.level == undefined ? null : this.selected_item.level.id,
            alias: this.selected_item.alias,
            topic: this.selected_item.topic
          };
          await this.$store.dispatch("alias/updateAlias", patchVars);
          this.reset();
        }

      },
      editAlias(item){

        this.selected_item = item
        this.edit_alias_title = "Edit Alias"
        this.editAliasModal = true

      },
      async addAlias(){
        this.isLoading = true;
        
        if(this.new_alias.subject.id && this.new_alias.alias && this.new_alias.topic){
          let postVars = {
            subject_id:this.new_alias.subject.id,
            level_id:this.new_alias.level.id,
            topic:this.new_alias.topic,
            alias:this.new_alias.alias
          }
          await this.$store.dispatch("alias/addAlias", postVars);
          this.reset()

        }
        else{
              Vue.notify({
                    group: 'notify',
                    type: 'error',
                    text: 'Subject / Alias / Topic is required!',
                    max: 5,
                    duration: 5000,
                    speed: 300
                })
        }
  
        console.log(this.new_alias)


        this.isLoading = false;
      },
      reset(){
        this.new_alias = {
          subject:{},
          level:{},
          topic:'',
          alias:''
        }
      }
    }

}
</script>